import './formbuilder.scss';

import { FetchManager, FriendlyCaptcha, ConditionalLogic } from 'js-pimcore-formbuilder';
import { createElementFromHTML, smoothScrollTo, getOffset } from '../../utilities/js/helper';

class FormBuilder {
    constructor (element) {
        this.$formBuilder = element;
        this.$form = this.$formBuilder.querySelector('form');
        this.firstError = false;
        this.$formbuilderHeader = this.$formBuilder.querySelector('[data-formbuilder="header"]');
        this.$formbuilderFooter = this.$formBuilder.querySelector('[data-formbuilder="footer"]');
        this.mainHeight = getComputedStyle(document.documentElement).getPropertyValue('--main-height') ? getComputedStyle(document.documentElement).getPropertyValue('--main-height') : 0;
        this.$submitButton = this.$form.querySelector('button[type="submit"]');
        this.$contactabilityDaySelect = this.$form.querySelector('select.contactability-days');
        this.$contactabilityTimeSelect = this.$form.querySelector('select.contactability-time');
        this.$$contactabilityTimeSelectOptions = this.$contactabilityTimeSelect?.querySelectorAll('option');
        this.$successTarget = this.$formBuilder.querySelector('[data-formbuilder="success"]');

        if (!this.$successTarget) {
            this.$successTarget = this.$formBuilder.closest('[data-formbuilder="success"]');
        }
    }

    initialize () {
        if (this.$contactabilityDaySelect && this.$contactabilityTimeSelect) {
            const $selectedTarget = this.$contactabilityDaySelect.options[this.$contactabilityDaySelect.selectedIndex];
            this.checkContactability($selectedTarget.getAttribute('data-formbuilder-date'));
        }

        this.setEvents();

        this.$form.addEventListener('submit', () => {
            if (this.$submitButton) {
                this.BtnLoader = createElementFromHTML(window.OPTIONS.loader);
                this.$submitButton.appendChild(this.BtnLoader);
            }
        });

        new FetchManager(this.$form, { // eslint-disable-line
            submitBtnSelector: 'button[type="submit"]',
            onSuccess: (messages, redirect, form) => {
                if (messages.length > 0) {
                    const successMessage = messages[0];

                    if (typeof successMessage.type !== 'undefined' && typeof successMessage.message !== 'undefined') {
                        if (successMessage.type === 'success') {
                            this.$successTarget.innerHTML = '';
                            this.$successTarget.appendChild(createElementFromHTML(successMessage.message));
                            this.$form.remove();
                            this.$formbuilderHeader?.remove();
                            this.$formbuilderFooter?.remove();
                            this.$successTarget.classList.add('is--visible');

                            window.dispatchEvent(
                                new CustomEvent('content.loaded', {
                                    view: window,
                                    bubbles: true,
                                    cancelable: false,
                                    detail: {
                                        $context: this.$successTarget
                                    }
                                })
                            );

                            smoothScrollTo(0, getOffset(this.$successTarget).top - 60, 400, () => {
                                this.firstError = false;
                            });
                        }
                    }
                } else {
                    if (redirect !== '') {
                        window.location.href = redirect;
                    }
                }
            },
            onRequestDone: (form) => {
                this.$submitButton.querySelector('.loading')?.remove();
            },
            onErrorField: (fieldError, form) => {
                console.log('formbuild error');

                if (!this.firstError) {
                    this.firstError = true;
                    let offset = getOffset(fieldError.field).top;
                    const tagName = fieldError.field.tagName.toLowerCase();

                    if (tagName === 'select' || (tagName === 'input' && (fieldError.field.type === 'radio' || fieldError.field.type === 'checkbox'))) {
                        offset = getOffset(fieldError.field.closest('.form__element')).top;
                    }

                    smoothScrollTo(0, offset + parseInt(this.mainHeight) - 60, 400, () => {
                        this.firstError = false;
                    });
                }
            },
            elementTransformer: {
                addValidationMessage: (form, field, messages) => {
                    const tagName = field.tagName.toLowerCase();

                    if (tagName === 'input' || tagName === 'textarea') {
                        console.log(field.type, tagName);
                        if (field.type === 'text' || field.type === 'email' || tagName === 'textarea' || field.type === 'date' || field.type === 'time') {
                            field.closest('.form__input').classList.add('has--error');
                        }

                        if (field.type === 'checkbox') {
                            field.closest('.form__checkbox').classList.add('has--error');
                        }

                        if (field.type === 'radio') {
                            field.closest('.form__radios-wrapper').classList.add('has--error');
                        }
                    } else {
                        if (tagName === 'select') {
                            field.closest('.form__select').classList.add('has--error');
                        } else {
                            field.classList.add('has--error');
                        }
                    }

                    form.classList.add('error-form');

                    messages.forEach((message) => {
                        const errorEl = document.createElement('div');
                        errorEl.className = 'form__error form__hint';
                        errorEl.innerText = message;

                        if (field.type === 'radio') {
                            field.closest('.form__radios-wrapper').after(errorEl);
                        } else if (field.type === 'checkbox') {
                            field.closest('.form__checkbox').after(errorEl);
                        } else {
                            field.after(errorEl);
                        }
                    });
                },
                removeFormValidations: (form) => {
                    form.classList.remove('error-form');
                    form.querySelectorAll('.has--error').forEach((el) => el.classList.remove('has--error'));
                    form.querySelectorAll('.form__error').forEach((el) => el.remove());
                }
            }
        });
    }

    checkContactability (selectedDay) {
        const now = new Date();
        const currentDate = now.toISOString().split('T')[0];
        const options = {};

        this.$$contactabilityTimeSelectOptions.forEach(($contactabilityTimeSelectOption, index) => {
            options[index] = {};
            options[index].value = $contactabilityTimeSelectOption.value;

            if (currentDate === selectedDay) {
                const optionEndTime = this.parseOptionEndTime($contactabilityTimeSelectOption.value);

                if (optionEndTime === 'select' || optionEndTime === null) {
                    options[index].disabled = false;
                } else {
                    if (optionEndTime && (optionEndTime.getTime() - now.getTime()) >= 3600000) {
                        options[index].disabled = false;
                    } else {
                        options[index].disabled = true;
                    }
                }
            } else {
                options[index].disabled = false;
            }
        });

        console.log(options);

        const customChangeEvent = new CustomEvent('customSelectChange', {
            detail: {
                select: this.$contactabilityTimeSelect,
                options
            }
        });

        window.dispatchEvent(customChangeEvent);
    }

    parseOptionEndTime (optionValue) {
        if (!optionValue) return null;

        const timeParts = optionValue.split('-')[1] || optionValue.split('-')[0]; // Nehme die Endzeit oder die einzelne Zeit
        const [hours, minutes] = timeParts.split(':').map(Number);

        if (!isNaN(hours) && !isNaN(minutes)) {
            const parsedDate = new Date();
            parsedDate.setHours(hours, minutes, 0, 0);
            return parsedDate;
        }

        return null;
    }

    setEvents () {
        if (this.$contactabilityDaySelect && this.$contactabilityTimeSelect) {
            this.$contactabilityDaySelect.addEventListener('change', (e) => {
                const $selectedTarget = e.target.options[e.target.selectedIndex];
                this.checkContactability($selectedTarget.getAttribute('data-formbuilder-date'));
            });
        }
    }
}

export { FormBuilder };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $formbuilder = $context.querySelector('[data-formbuilder="root"]');

        if ($formbuilder) {
            const formbuilder = new FormBuilder($formbuilder);
            formbuilder.initialize();
            const $form = document.querySelector('.ajax-form');
            new FriendlyCaptcha($form); // eslint-disable-line
            new ConditionalLogic($form); // eslint-disable-line
        }
    }
});
